// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"3.451.0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { getEnv } from 'common-nextjs';
import { sentryDenyUrls, sentryIgnoreErrors } from '~/sentry.ignores';

// const allowUrls = [/https?:\/\/.*\.?sidelineswap\.com/i];

Sentry.init({
  // allowUrls: [/https?:\/\/.*\.?sidelineswap\.com/i],
  ignoreErrors: sentryIgnoreErrors,
  denyUrls: sentryDenyUrls,
  dsn:
    // process.env.SENTRY_DSN ||
    // process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://9a37bc078bbd429ab7e930cf277e022b@o28201.ingest.us.sentry.io/1512457',
  tracesSampleRate: 0.2,
  environment: getEnv(),
  beforeSend(event, hint) {
    // if (getEnv() !== 'production') {
    //   console.log('Sending event to Sentry', event, hint);
    // }

    // Only log errors for logged in users
    if (document.cookie.includes('access_token')) {
      return event;
    } else {
      return null;
    }
  },
  // integrations: [
  //   Sentry.rewriteFramesIntegration({
  //     iteratee: frame => {
  //       frame.filename = frame.filename?.replace('~/_next', '/web/_next');
  //       return frame;
  //     },
  //   }),
  // ],
});
